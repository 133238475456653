import React, { useContext, useState } from "react";
import "../../assets/style/globalStyle.scss";
import { navigate } from "gatsby";
import {
  responseToUserRequest,
  changeUserResponseStatus,
} from "../../services/userRequestService";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { GlobalStateContext } from "../../context/GlobalContextProvider";

const AnswerUserRequestPage = () => {
  const state = useContext(GlobalStateContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalStatusVisible, setIsModalStatusVisible] = useState(false);
  const [response, setResponse] = useState({
    body: "",
    author: "5ed7a8c1f35fb0cc6fc54eac",
    createAt: Date.now,
  });
  const [body, setBody] = useState("");

  let modalUpdateClassName = "modal";
  if (isModalVisible) {
    modalUpdateClassName += " is-active";
  }
  let modalStatusClassName = "modal";
  if (isModalStatusVisible) {
    modalStatusClassName += " is-active";
  }

  if (!state) {
    return (
      <div className="container is-fullhd" style={{ width: "880px" }}>
        <h1>il n'y a rien</h1>
      </div>
    );
  }

  const { content, subject, title, createdAt, status, _id, author } =
    state.userRequest;

  return (
    <div className="box">
      <div className="columns">
        <div className="column">
          <div className="field">
            <p className="title is-5">Demande {_id} </p>
            <CopyToClipboard text={_id}>
              <button className="button is-success is-outlined">
                Copier l'ID de la demande
              </button>
            </CopyToClipboard>
          </div>
        </div>
        <div className="column is-one-quarter">
          <div className="field">
            <p style={{ fontWeight: "Bold" }}>Auteur</p>
            <p>{author.userName ? author.userName : author.firstName}</p>
          </div>
        </div>
      </div>
      <br />
      <div className="columns">
        <div className="column">
          <div className="field">
            <p style={{ fontWeight: "Bold" }}>Sujet</p>
            <p>{subject}</p>
          </div>
        </div>
        <div className="column">
          <div className="field">
            <p style={{ fontWeight: "Bold" }}>Titre</p>
            <p>{title}</p>
          </div>
        </div>
        <div className="column">
          <div className="field">
            <p style={{ fontWeight: "Bold" }}>Status</p>
            <p>{status}</p>
          </div>
        </div>
        <div className="column">
          <div className="field">
            <p style={{ fontWeight: "Bold" }}>Date de création</p>
            <p>
              {new Intl.DateTimeFormat("fr-FR").format(Date.parse(createdAt))}
            </p>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <br />
      {content !== undefined &&
        content.length !== 0 &&
        content.map((item) => (
          <div class="card" key={item._id}>
            <div class="card-content">
              <p class="subtitle">{item.body}</p>
              <div class="media">
                <div class="media-left">
                  <figure class="image is-48x48">
                    {item.author.image ? (
                      <img
                        src={`https://res.cloudinary.com/ball-n-connect/image/upload/v1/profile_image/${item.author.userId}.jpg`}
                        alt="Profile"
                      />
                    ) : (
                      <img
                        src={`https://res.cloudinary.com/ball-n-connect/image/upload/v1/profile_image/5ed64a403a0ee7b30b632328.jpg`}
                        alt="Profile"
                      />
                    )}
                  </figure>
                </div>
                <div class="media-content">
                  <p class="title is-4">
                    {item.author.userName
                      ? item.author.userName
                      : item.author.firstName}
                  </p>
                  {item.author.instagram ? (
                    <p class="subtitle is-6">@{item.author.instagram}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))}
      {status === "Traitement en cours" ? (
        <>
          <br />
          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label">
                  {""}
                  <input
                    className="input"
                    type="text"
                    placeholder={"Envoyer une réponse à la demande"}
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                  />
                </label>
              </div>
            </div>
            <div className="column is-one-quarter">
              <div className="field">
                <button
                  className="button is-success is-outlined"
                  type="submit"
                  onClick={() => {
                    setResponse({
                      body: body,
                      author: "5ed7a8c1f35fb0cc6fc54eac",
                      createAt: Date.now,
                    });
                    setIsModalVisible(true);
                  }}
                >
                  Envoyer la réponse
                </button>
              </div>
            </div>
            <div className="column is-one-quarter">
              <div className="field">
                <button
                  className="button is-link is-outlined"
                  type="submit"
                  onClick={() => {
                    setIsModalStatusVisible(true);
                  }}
                >
                  Fermer la demande
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div className={modalUpdateClassName}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Cloturer</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setIsModalVisible(false)}
            />
          </header>
          <section className="modal-card-body">
            Voulez-vous cloturer cette demande avec ce message ?
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-success"
              onClick={() => {
                setIsModalVisible(false);
                responseToUserRequest({ requestId: _id, response: response });
                changeUserResponseStatus({
                  requestId: _id,
                  status: "Terminée",
                });
              }}
            >
              Oui
            </button>
            <button
              className="button"
              onClick={() => {
                setIsModalVisible(false);
                responseToUserRequest({ requestId: _id, response: response });
              }}
            >
              Non
            </button>
          </footer>
        </div>
      </div>
      <div className={modalStatusClassName}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Cloturer</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setIsModalStatusVisible(false)}
            />
          </header>
          <section className="modal-card-body">
            Voulez-vous cloturer cette demande ?
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-success"
              onClick={() => {
                setIsModalStatusVisible(false);
                changeUserResponseStatus({
                  requestId: _id,
                  status: "Terminée",
                });
                navigate("/app/allUserRequests");
              }}
            >
              Oui
            </button>
            <button
              className="button"
              onClick={() => {
                setIsModalStatusVisible(false);
              }}
            >
              Non
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default AnswerUserRequestPage;
