import trackerApi from "../api/tracker";
import { navigate } from "gatsby";

export const responseToUserRequest = async ({ requestId, response }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const userRequest = await trackerApi.post(
      "/addResponseToUserRequest",
      {
        token,
        requestId,
        response,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    navigate("/app/allUserRequests");
    return userRequest.data;
  } catch (error) {
    console.log("error lors de la réponse à la demande: ", error);
  }
};

export const getAllUserRequests = async () => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const response = await trackerApi.get("/getAllUserRequest", {
      headers: { Authorization: `Bearer ${token}` },
      params: { token },
    });
    return response.data;
  } catch (error) {
    console.log("error lors de la récupération de toutes les demandes:", error);
  }
};

export const getUserRequestById = async (id) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;
  const origin = "crm";

  try {
    const userRequest = await trackerApi.get(`/getUserRequestById/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { token, origin },
    });
    return userRequest.data;
  } catch (error) {
    console.log("error lors de la récupération de la demande: ", error);
  }
};

export const changeUserResponseStatus = async ({ requestId, status }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    await trackerApi.post(
      "/changeUserResponseStatus",
      {
        token,
        requestId,
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.log("error lors de la mise à jour de la demande");
  }
};
